import type { Ref } from '@nuxtjs/composition-api';
import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';

/**
 * @public
 *
 * Return messages data
 */
export function useGetBestSellingProducts(): GetBestSellingProductsInterface {

  const loading: Ref<boolean> = ref(false);
  const { app } = useContext();
  const context = app.$vsf;

  const loadProducts = async (customer_code) => {
    Logger.debug('[Magento] before load products');
    let results = null;

    try {
      loading.value = true;
      const { data } = await context.$magento.api.getBestSellingProducts(customer_code);
      results = data?.getBestSellingProducts ?? [];
      Logger.debug('[Magento] loaded products:', results);
    } catch (err) {
      Logger.error('Magento] load products', err);
    }
    return results;
  }

  return { loadProducts };
}

export * from './useGetBestSellingProducts';
export default useGetBestSellingProducts;
